"use client";
import React, { useState } from "react";
import SignInPopup from "./SignInPopup";
import SignUpPopup from "./SignUpPopup";
import { useTranslation } from "react-i18next";

function Login() {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <button
        className="bg-transparent border border-white text-white py-1 px-3 rounded hover:bg-white hover:text-green-800"
        onClick={() => setIsSignInOpen(true)}
      >
        {/* {t("signIn")} */}
        Sign In
      </button>
      {isSignInOpen && (
        <SignInPopup
          isSignInOpen={isSignInOpen}
          setIsSignInOpen={setIsSignInOpen}
          setIsSignUpOpen={setIsSignUpOpen}
        />
      )}
      {isSignUpOpen && (
        <SignUpPopup
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
    </div>
  );
}

export default Login;
