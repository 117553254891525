import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  token: null,
  email: null,
  passengerData: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.email = action.payload.email;
      if (typeof window !== "undefined") {
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + 60 * 60 * 1000;
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("email", action.payload.email);
        localStorage.setItem("tokenExpiration", expirationTime);
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.email = null;
      state.passengerData = [];
      if (typeof window !== "undefined") {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("tokenExpiration");
      }
    },
    loadUserFromLocalStorage: (state) => {
      if (typeof window !== "undefined") {
        const token = localStorage.getItem("token");
        const email = localStorage.getItem("email");
        const expirationTime = localStorage.getItem("tokenExpiration");
        const currentTime = new Date().getTime();

        // Check if the token is expired
        if (expirationTime && currentTime > expirationTime) {
          // Token has expired, clear it
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("tokenExpiration");
        } else if (token && email) {
          state.isLoggedIn = true;
          state.token = token;
          state.email = email;
        }
      }
    },
    setPassengerData: (state, action) => {
      state.passengerData = action.payload;
    },
  },
});

export const { login, logout, loadUserFromLocalStorage, setPassengerData } =
  authSlice.actions;

export default authSlice.reducer;
