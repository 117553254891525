import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi) // loads translations from /src/language
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass i18n down to react-i18next
  .init({
    fallbackLng: "en", // default language
    supportedLngs: ["en", "bn"],
    detection: {
      order: ["path", "Privacy", "htmlTag"], // detect language by URL, Privacy, or HTML tag
      caches: ["Privacy"],
    },
    backend: {
      loadPath: "/language/{{lng}}/translation.json", // Updated load path
    },
    react: { useSuspense: false }, // Next.js SSR doesn't support Suspense
  });

export default i18n;
