"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "@/redux/authSlice";
import logo from "../../public/hams-logo-white.png";
import Login from "@/components/singIn/Login";
import userProfile from "../../public/profile.png";
import { usePathname } from "next/navigation";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const dispatch = useDispatch();
  const { isLoggedIn, email } = useSelector((state) => state.auth);
  const pathName = usePathname();
  const axiosURL = globalAxiosURL();
  const [user, setUser] = useState({});
  const { t } = useTranslation();
  const menuRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  //
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 100 && currentScrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // Clicking outside
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  const links = [
    // {
    //   href: "/",
    //   text: "Home",
    // },
    {
      href: "/",
      text: "Flights",
    },
    // Add links here
  ];

  const getUser = async () => {
    if (email) {
      const { data } = await axiosURL.get(`/auth/get-me?email=${email}`);
      setUser(data);
    }
  };

  useEffect(() => {
    getUser();
  }, [email]);

  return (
    <main>
      <nav
        className={`bg-[#00703E] fixed top-0 w-full z-[9999] h-[60px] transition-transform duration-200 ${
          showNavbar ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="p-1 flex items-center justify-between lg:px-16 md:px-10 px-3 mx-auto h-full">
          <div className="flex relative items-center" ref={menuRef}>
            {/* <div
              onClick={() => setIsOpen(!isOpen)}
              className="inline-block lg:hidden text-white pr-2"
            >
              <LuMenu size={20} />
            </div> */}
            <div
              className={`fixed h-screen shadow-[0_3px_10px_rgb(0,0,0,0.2)] top-0 left-0 bg-[#00703E] text-white py-5 w-[200px] sm:w-[270px] transform ${
                isOpen ? "translate-x-0 overflow-y-auto" : "-translate-x-full"
              } transition-transform duration-300 ease-in-out z-10  shadow-xl`}
            >
              <div
                onClick={() => setIsOpen(false)}
                className="flex justify-between"
              >
                <Link href="/">
                  <Image src={logo} alt="Logo" className="w-24 h-24" />
                </Link>
                <div className="p-2 rounded-full mr-2">
                  <IoClose size={20} />
                </div>
              </div>
              <ul className="flex mt-3 flex-col font-medium border-b border-green-600">
                {links.map((li) => (
                  <Link
                    href={li.href}
                    onClick={() => setIsOpen(false)}
                    key={li.text}
                    className={`py-2 text-sm px-5 border-t border-green-600 inline-block hover:bg-[#ff5e000e] ${
                      pathName === li.href ? "text-base font-semibold" : ""
                    }`}
                  >
                    {li.text}
                  </Link>
                ))}
              </ul>
            </div>
            {/* Overlay */}
            {isOpen && (
              <div
                className={`fixed h-screen inset-0 bg-black bg-opacity-50 `}
                onClick={handleClick}
              ></div>
            )}
            <Link href="/">
              <Image src={logo} alt="Logo" className="w-[80px] md:w-[100px]" />
            </Link>
          </div>
          <div className="lg:flex md:hidden  dropdown md:gap-x-5 text-white">
            <Link
              href={"/"}
              className={`px-1 -ml-7 md:ml-0 text-sm md:text-base ${
                pathName === "/" ||
                pathName === "/searchResult" ||
                pathName === "/bookFlights" ||
                pathName === "/confirmPage"
                  ? "font-bold"
                  : "hover:border-b"
              }`}
            >
              {"Flight"}
            </Link>

            {/* <Link
              href="/homePage"
              className="hover:underline"
              aria-current="page"
            >
              Home
            </Link>
            <Link href="/flights" className="hover:underline">
              Flights
            </Link> */}
            {/*
            <a href="#" className="hover:underline">
              Train
            </a>
            <a href="#" className="hover:underline">
              Cars
            </a>
            <a href="#" className="hover:underline">
              Bus
            </a>
            <a href="#" className="hover:underline">
              Tour Package
            </a>
            <a href="#" className="hover:underline">
              Visa Application
            </a>
            <a href="#" className="hover:underline">
              Track Flight
            </a> */}
          </div>
          <div className="flex items-center space-x-2">
            {/* <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                clipRule="evenodd"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </button> */}
            {!isLoggedIn ? (
              <>
                <Login />
              </>
            ) : (
              <>
                <Link href="/dashboard">
                  <div className="size-10 rounded-full overflow-hidden">
                    <Image
                      className="w-full h-full object-cover"
                      src={user?.profileImage || userProfile}
                      width={50}
                      height={50}
                      alt="Picture of the author"
                    />
                  </div>
                </Link>
                {/* <span className="text-white">Welcome, {email}</span> */}
              </>
            )}
          </div>
        </div>
      </nav>
    </main>
  );
};
export default Navbar;
